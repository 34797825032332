import './App.css';

function App() {
  return (
    <div className="App">
      <div className="wave-container">
        <div className="wave"></div>
      </div>
      <h1>Coming Soon...</h1>
      <footer>
        <p>A product by</p>
        <a
          style={{
            textDecoration: "none",
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
          target="blank"
          href="https://prodshell.com"
        >
          ProdShell
        </a>
      </footer>
    </div>
  );
}


export default App;
